<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background:new 0 0 512.001 512.001;"
    xml:space="preserve"
  >
    <path
      style="fill:#FF507D;"
      d="M253.545,0.014C142.199,1.368,62.2,105.245,79.232,215.29
	c18.016,116.394,120.4,185.449,171.517,293.479c2.04,4.312,8.016,4.308,10.055-0.002
	c56.172-118.713,174.254-190.362,174.254-329.484C435.058,79.524,353.582-1.203,253.545,0.014z M255.776,304.779
	c-69.31,0-125.497-56.187-125.497-125.497S186.466,53.785,255.776,53.785s125.497,56.187,125.497,125.497
	S325.086,304.779,255.776,304.779z"
    />
    <path
      style="fill:#AFB9D2;"
      d="M318.525,125.497H214.037l-1.541-5.148c-2.271-7.585-9.25-12.781-17.167-12.781h-11.267
	c-4.95,0-8.964,4.014-8.964,8.964l0,0c0,4.95,4.014,8.964,8.964,8.964h11.258l21.138,70.47
	c3.413,11.379,13.887,19.171,25.766,19.171h58.372c14.852,0,26.892-12.04,26.892-26.892v-53.785
	C327.489,129.511,323.475,125.497,318.525,125.497z M309.561,188.246c0,4.941-4.018,8.964-8.964,8.964h-58.372
	c-3.992,0-7.441-2.569-8.588-6.386l-14.217-47.399h90.14v44.82H309.561z"
    />
    <g>
      <circle style="fill:#7F8499;" cx="224.405" cy="237.549" r="13.446" />
      <circle style="fill:#7F8499;" cx="305.082" cy="237.549" r="13.446" />
    </g>
    <path
      style="fill:#D23C69;"
      d="M399.127,174.611c-2.26-71.129-58.439-131.099-129.262-138.061
	c-18.27-1.796-35.857-0.039-52.28,4.536c-5.577,1.553-10.076-4.467-6.956-9.343c12.375-19.34,27.337-31.065,43.86-31.691
	c-0.318,0.002-0.625-0.04-0.945-0.037C142.199,1.368,62.2,105.245,79.232,215.29c18.017,116.394,120.401,185.449,171.517,293.479
	c1.034,2.187,3.079,3.242,5.113,3.208c-0.892-0.03-1.779-1.085-2.233-3.208c-15.31-71.609-40.715-126.183-58.509-188.705
	c-1.4-4.919,3.507-9.254,8.263-7.372c16.229,6.423,33.878,10.016,52.395,10.016C336.539,322.708,401.712,255.953,399.127,174.611z
	 M381.273,179.282c0,69.31-56.187,125.497-125.497,125.497s-125.497-56.187-125.497-125.497S186.466,53.785,255.776,53.785
	S381.273,109.972,381.273,179.282z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
